import { CheckboxAccept } from 'Lib/checkbox-accept/checkbox-accept';

const cCheckboxForm = '.checkbox-accept-form';

function setEventCheckboxes() {
    document.querySelectorAll(cCheckboxForm).forEach((item) => {
        const checkboxes = new CheckboxAccept(
            item.getAttribute('data-form-name'),
            '.checkbox-accept-form__checkbox',
            '.checkbox-accept-form__input',
            '.checkbox-accept-form__content-offset',
            '.checkbox-accept-form__btn',
            item,
        );

        checkboxes.setEventCheckboxAccept();
    });
}

setEventCheckboxes();
$('.m-menu').on('eksmoSetMMenu', setEventCheckboxes);