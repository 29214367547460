import 'jquery';
import 'Lib/on-width-resize/on-width-resize';
import 'Lib/touch-retina-to-html-tag/touch-retina-to-html-tag';
import 'Lib/es-page-open/es-page-open';
import { svgSpriteLoader } from 'Lib/svg-sprite-loader/svg-sprite-loader';
import { onScreen } from 'Lib/on-screen/on-screen';
import { getW } from 'Lib/w/w';
import { eksmoBP } from 'Lib/bp/bp';
import { onBpChange } from 'Lib/on-bp-change/on-bp-change';
import 'Lib/js-anchor-link/js-anchor-link';
import 'Bem/site/book/book';
import 'Bem/site/book-preview/book-preview';
import 'Bem/site/tabs/tabs';
import 'Bem/site/spoiler/spoiler';
import 'Bem/site/books-slider/books-slider';
import 'Bem/site/sales-book/sales-book';
import 'Bem/site/link-drop-down/link-drop-down';
import 'Bem/site/ajax-form/ajax-form';
import 'Bem/site/checkbox-accept/checkbox-accept';
import 'Bem/site/checkbox/checkbox';
import 'Bem/site/img-loader/img-loader';
import 'Vendor/owlcarousel/owl.carousel.min';
import { speed } from 'Lib/speed/speed';
import { PopupVideo } from 'Lib/popup/popup-video';
import 'Bem/site/book3d/book3d';

const cSliderOn = 'owl-carousel';
const bg = document.querySelectorAll('.what-read-next__parallax');
const elTimer = document.querySelector('.what-read-next__timer');
const $sliderFooter = $('.what-read-next__footer-edition-box');
const popupVideo = new PopupVideo();

popupVideo.popupVideoOpenDefault();

function setScrollAnim(contProperty, el, styleProperty) {
    const coords = el.getBoundingClientRect();
    const count = getW() > eksmoBP.xlMin ? 6 : 3;

    if (coords.top < 700 && coords.top > 200) {
        contProperty.style.setProperty(styleProperty, (coords.top - 400) / count);
    }
}

window.addEventListener('scroll', () => {
    setScrollAnim(document.querySelector('.what-read-next__screen5_non-fiction'), document.querySelector('.what-read-next__screen5_non-fiction .what-read-next__screen5-dragon'), '--scroll-non-fiction');
    setScrollAnim(document.querySelector('.what-read-next__screen5_fiction'), document.querySelector('.what-read-next__screen5_fiction .what-read-next__screen5-dragon'), '--scroll-fiction');
});

onScreen($('.what-read-next__section'), ($el) => {
    $el.addClass('what-read-next__section_visible');
}, 200, 200, 1.6);

if (getW() > eksmoBP.mdMin) {
    for (let i = 0; i < bg.length; i++) {
        window.addEventListener('mousemove', (e) => {
            const x = e.clientX / window.innerWidth;
            const y = e.clientY / window.innerHeight;

            bg[i].style.transform = `translate(-${x * 50}px, -${y * 50}px)`;
        });
    }
}

$('.what-read-next__screen6-form.ajax-form').on('eksmoThisAjaxFormSuccess', function () {
    const $this = $(this);

    $this.get(0).reset();
    $this.find('.what-read-next__screen6-form-btn.ajax-form__btn').addClass('btn_disabled').attr('disabled', 'disabled');
});

onScreen($('.what-read-next__animation'), ($el) => {
    $el.addClass('what-read-next__animation_active');
}, 0, 0, 0.8);

document.addEventListener('DOMContentLoaded', () => {
    // конечная дата
    const deadline = new Date(new Date().getFullYear(), new Date().getMonth(), Number(elTimer.dataset.date), Number(elTimer.dataset.hours));
    // id таймера
    let timerId = null;

    // склонение числительных
    function declensionNum(num, words) {
        return words[(num % 100 > 4 && num % 100 < 20) ? 2 : [2, 0, 1, 1, 1, 2][(num % 10 < 5) ? num % 10 : 5]];
    }

    // вычисляем разницу дат и устанавливаем оставшееся времени в качестве содержимого элементов
    function countdownTimer() {
        const diff = deadline - new Date();

        if (diff <= 0) {
            clearInterval(timerId);
        }

        const days = diff >= 0 ? Math.floor(diff / 1000 / 60 / 60 / 24) : 0;
        const hours = diff > 0 ? Math.floor(diff / 1000 / 60 / 60) % 24 : 0;
        const minutes = diff > 0 ? Math.floor(diff / 1000 / 60) % 60 : 0;
        const seconds = diff > 0 ? Math.floor(diff / 1000) % 60 : 0;

        document.querySelectorAll('.what-read-next__timer').forEach((item) => {
            // получаем элементы, содержащие компоненты даты
            const elDays = item.querySelector('.what-read-next__timer-item_days');
            const elHours = item.querySelector('.what-read-next__timer-item_hours');
            const elMinutes = item.querySelector('.what-read-next__timer-item_minutes');
            const elSeconds = item.querySelector('.what-read-next__timer-item_seconds');

            elDays.textContent = days < 10 ? `0${days}` : days;
            elHours.textContent = hours < 10 ? `0${hours}` : hours;
            elMinutes.textContent = minutes < 10 ? `0${minutes}` : minutes;
            elSeconds.textContent = seconds < 10 ? `0${seconds}` : seconds;
            elHours.dataset.title = declensionNum(hours, ['час', 'часа', 'часов']);
            elMinutes.dataset.title = declensionNum(minutes, ['минута', 'минуты', 'минут']);
            elSeconds.dataset.title = declensionNum(seconds, ['секунда', 'секунды', 'секунд']);
        });
    }

    // вызываем функцию countdownTimer
    countdownTimer();
    // вызываем функцию countdownTimer каждую секунду
    timerId = setInterval(countdownTimer, 1000);
});

function setSliders() {
    $sliderFooter.each(function () {
        $(this).trigger('destroy.owl.carousel')
            .removeClass(cSliderOn)
            .addClass(cSliderOn)
            .owlCarousel({
                items: 3,
                slideBy: 3,
                smartSpeed: speed,
                dots: false,
                nav: true,
                navClass: ['what-read-next__prev arrow arrow_prev arrow_shadow arrow_no-bg arrow_border', 'what-read-next__next arrow arrow_next arrow_shadow arrow_no-bg arrow_border'],
                navText: ['', ''],
                mouseDrag: true,
                touchDrag: true,
                margin: 40,
            });
    });
}

if (getW() > eksmoBP.smMax) {
    setSliders();
}

onBpChange(eksmoBP.smMax, (isLess) => {
    if (isLess) {
        $sliderFooter.trigger('destroy.owl.carousel').removeClass(cSliderOn);
    } else {
        setSliders();
    }
});

svgSpriteLoader('/landing/chto-chitat-dalshe/svg/sprite.svg?v=201909112033');